import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Card from './Card';
import FeaturedRibbon from './FeaturedRibbon';
import Star from './Star';
import TextButton from './TextButton';
import ViewMenusButton from './ViewMenusButton';

import theme from 'theme';

const newStarSize = 52;
const useStyles = makeStyles(theme => ({
	attributeList: {
		'& + &': {
			margin: theme.spacing(1, 0),
		},
	},
	description: {
		margin: theme.spacing(2, 0, 0),
	},
	name: {
		marginBottom: theme.spacing(2),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	newStarContainer: {
		position: 'absolute',
		right: theme.spacing(2),
		top: -(newStarSize + theme.spacing(2)),
	},
}));

const PartnerCard = ({ partner, showFeaturedRibbon, showSporkLocations }) => {
	const classes = useStyles({ partner, showFeaturedRibbon });

	return (
		<Card
			action={
				process.env.GATSBY_MAINTENANCE_MODE === 'false' && (
					<ViewMenusButton
						partner={partner}
						showGetStartedButton={true}
						showPartnerInDialog={false}
					>
						{onClick => (
							<TextButton
								action={onClick}
								text="Learn More"
								title={`Learn more about ${partner.name}`}
							/>
						)}
					</ViewMenusButton>
				)
			}
			borderColor={theme.palette.brand.pink}
			image={partner.localPhoto.childImageSharp.fluid}
			imageAlt={partner.name}
			imageHeight={150}
		>
			{partner.isNewPartner && (
				<div className={classes.newStarContainer}>
					<Star
						backgroundColor="white"
						fontSize={16}
						size={newStarSize}
						text="NEW!"
						textColor={theme.palette.brand.salmon}
					/>
				</div>
			)}
			<Typography
				variant="h4"
				component="h2"
				className={classes.name}
				title={partner.name}
			>
				{partner.name}
			</Typography>
			{partner.isFeaturedPartner && showFeaturedRibbon && (
				<FeaturedRibbon />
			)}
			{showSporkLocations && (
				<Typography
					variant="h6"
					component="h3"
					className={classes.attributeList}
				>
					Locations:{' '}
					{partner.SporkLocations?.map(({ name }) => name).join(', ')}
				</Typography>
			)}
			<Typography
				variant="h6"
				component="h3"
				className={classes.attributeList}
			>
				Tags: {partner.PartnerTags.map(({ name }) => name).join(', ')}
			</Typography>
			<Typography
				variant="h6"
				component="h3"
				className={classes.attributeList}
			>
				Meals: {partner.MealTypes.map(({ name }) => name).join(', ')}
			</Typography>
			<Typography
				component="p"
				className={classes.description}
				dangerouslySetInnerHTML={{
					__html: partner.description,
				}}
			/>
		</Card>
	);
};

PartnerCard.propTypes = {
	partner: PropTypes.object.isRequired,
	showFeaturedRibbon: PropTypes.bool,
	showSporkLocations: PropTypes.bool,
};

PartnerCard.defaultProps = {
	showFeaturedRibbon: false,
};

export default PartnerCard;
